<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.checkIn') }} / {{ $t('message.checkOut') }}</h3>
    <calendarBreadcrumbs :link="link" />
    <div v-if="$route.params.id !== 'all'" :key="$route.params.id">
      <calendarPassword :calendar="$route.params.id" />
      <router-link class="flix-btn flix-btn-default flix-btn-xs" :to="{name: 'checkIn', params: {id: 'index.html'}}">{{ $t('message.goTo', { name: $t('message.checkIn') + ' / ' + $t('message.checkOut') }) }}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    calendarPassword () { return import('./password.vue') }
  },
  props: {},
  data () {
    return {
      link: 'dashboardCheckIn'
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
